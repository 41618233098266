import React, { memo } from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Space from '../space';




const NossaEmpresaPicture = ({name}) =>
{
    const data = useStaticQuery(graphql`
        query {
            divisor1: file(relativePath: { eq: "divisor1.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === 'divisor1.png' ) {
        return <img draggable="false" src={data.divisor1.childImageSharp.fluid.src} alt="" width="595" height="45" />
    }
}




const TempBlocoNossaEmpresa = () =>
{
    /* useEffect(() => {
        console.log('BlocoNossaEmpresa:','render');
        return () => {
            console.log('BlocoNossaEmpresa:','unmount');
        };
    }, []); */

    return (
        <div className="nossaempresa" key={`siteempresa`}>
            <div className="container">
                <Space altura={40} />
                <h2 className="h2 h2--dark h2--thin" data-aos="fade-in" data-aos-duration="1000">Nossa Empresa</h2>
                <Space altura={5} />
                <p className="text__1" data-aos="fade-in" data-aos-duration="1000">Com serviço de inteligência fiscal e tributária, levamos ao cliente soluções tributárias que visam aumentar a competitividade no mercado e recuperar créditos tributários administrativamente, com segurança e agilidade para empresas de todos os regimes tributários.</p>
                <Space altura={15} />
                <NossaEmpresaPicture name="divisor1.png" />
                <Space altura={60} />
            </div>
        </div>
    );
}

const BlocoNossaEmpresa = memo(TempBlocoNossaEmpresa);

export default BlocoNossaEmpresa;