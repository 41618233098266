import React, { memo } from 'react';

import Space from '../space';



const TempBlocoRessarcitometro = () =>
{
    /* useEffect(() => {
        console.log('BlocoRessarcitometro:','render');
        return () => {
            console.log('BlocoRessarcitometro:','unmount');
        };
    }, []); */

    return (
        <div className="blocoressarcitometro" key={`siteressarcitometro`}>
            <div className="container">

                <Space altura={40} />

                <h2 className="h2 h2--white h2--thin" data-aos="fade-in" data-aos-duration="1000">RESSARCITÔMETRO (R$)</h2>
                <Space altura={20} />
                <div className="ressarcitometro">
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1000">8<span className="ressarcitometro_milhoes">Milhões</span></span>
                    <span className="ressarcitometro_acento" data-aos="flip-left" data-aos-duration="1000">.</span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1000">6</span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1500">1<span className="ressarcitometro_mil">Mil</span></span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="2000">1</span>
                    <span className="ressarcitometro_acento" data-aos="flip-left" data-aos-duration="1000">.</span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1000">3</span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1500">2<span className="ressarcitometro_reais">Reais</span></span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="2000">1</span>
                    <span className="ressarcitometro_acento" data-aos="flip-left" data-aos-duration="1000">,</span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1000">0<span className="ressarcitometro_centavos">Centavos</span></span>
                    <span className="ressarcitometro_numero" data-aos="flip-left" data-aos-duration="1500">1</span>
                </div>

                <Space altura={40} />

            </div>
        </div>
    );
}

const BlocoRessarcitometro = memo(TempBlocoRessarcitometro);

export default BlocoRessarcitometro;